<template>
  <div>
    <template v-if="product.getProductStatus.getProductStatus == 'T'">
      <h2>{{ product.getProductStatus.getTemplateName }}</h2>
      <h3 class="text-muted">Default Data Template</h3>
    </template>
    <template v-else>
      <h2>{{ product.getMainTitle }}</h2>
      <h3
        v-if="
          product.getFormatName || product.getProductStatus.getProductStatus
        "
        class="text-muted"
      >
        <template v-if="product.getFormatCategory == 'X'">
          Mixed-media product
        </template>
        <template v-else-if="product.getFormatName">
          {{ product.getFormatName
          }}<template
            v-if="
              product.getFormatDetailsNames &&
              product.getFormatDetailsNames.length > 0 &&
              isEbook
            "
          >
            –
            {{ product.getFormatDetailsNames[0] }}</template
          ></template
        >
        <product-status-badge
          v-if="product.getProductStatus.getProductStatus"
          :status="product.getProductStatus.getProductStatus"
        ></product-status-badge>
        <product-visibility-badge
          v-if="product.getProductStatus.getProductVisibility"
          :visibility="product.getProductStatus.getProductVisibility"
        >
        </product-visibility-badge>
      </h3>
    </template>
    <div v-if="$permitted('export/dissemination-schedule')" class="row">
      <div class="col mt-2">
        <b-card
          v-if="disseminationErrorStatus == 'ERRORS'"
          no-body
          border-variant="danger"
          header-border-variant="danger"
        >
          <div class="p-1">
            This record has errors for at least one dissemination recipient. To
            view the errors please click on the Statistics tab.
          </div>
        </b-card>
        <b-card
          v-if="disseminationErrorStatus == 'WARNINGS'"
          no-body
          border-variant="warning"
        >
          <div class="p-1">
            This record has warnings for at least one dissemination recipient.
            To view the warnings please click on the Statistics tab.
          </div>
        </b-card>
      </div>
    </div>
    <div
      v-if="product.getProductStatus.getProductStatus == 'D'"
      class="row mt-2"
    >
      <div class="col">
        <b-card no-body border-variant="info" header-border-variant="info">
          <div class="p-1">
            In Archived Title Records display, not all fields are visible.
            Restore this record to see all fields but this will count towards
            your BooksoniX subscription.
          </div>
        </b-card>
      </div>
    </div>
    <div v-if="markedRecords" class="row my-2">
      <div class="col">
        <b-form-checkbox
          id="markedCheckbox"
          v-model="recordMarked"
          name="markedCheckbox"
          :value="true"
          :unchecked-value="false"
        >
          Mark record
          <info-icon tip="markedRecords"></info-icon>
        </b-form-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <assigned-tags
          ref="productTags"
          :record-id="productId"
          :tags="product.getTags"
          :all-tags="tags"
          :allow-edit="allowEdit && !hideEditButtons"
          :search-id="searchId"
          :position="page"
        ></assigned-tags>
      </div>
    </div>
    <div class="row avoid-break">
      <div class="col-8">
        <title-and-identifiers
          ref="titleAndIdentifiers"
          class="mt-2"
          :product-id="productId"
          :product="product"
          :logon-id="logonId"
          :display="display"
          :return-to="returnTo + encodeURIComponent('#title-and-identifiers')"
          :search-id="searchId"
          :allow-edit="allowEdit"
          :page="page"
          :has-custom-fields="hasCustomFields"
          data-v-step="biblio-detail-cards"
        ></title-and-identifiers>
      </div>
      <div class="col-4">
        <product-media-img
          class="mt-2"
          :product-id="productId"
          :options="{ types: 'IMG_FRONT' }"
          :share-media-modal-id="shareMediaModalId"
          :logon-id="logonId"
          :date-options="dateOptions"
          :allow-edit="allowEdit"
          :disable-overlay="allowEdit == false"
          :img-width="400"
          data-v-step="biblio-detail-coverimage"
        ></product-media-img>
      </div>
    </div>
    <div class="row avoid-break">
      <div class="col">
        <product-bibliographic-info
          class="mt-4"
          :product-id="productId"
          :product="product"
          :return-to="returnTo + encodeURIComponent('#bibliographic')"
          :display="display"
          :date-options="dateOptions"
          :allow-edit="allowEdit"
          :has-custom-fields="hasCustomFields"
        ></product-bibliographic-info>
      </div>
    </div>
    <div class="row avoid-break">
      <div class="col">
        <product-contributors-card
          :product-id="productId"
          :contributors="product.getContributors.get"
          :contributors-statement="product.getContributorsStatement"
          :display="display"
          :return-to="returnTo + encodeURIComponent('#contributors')"
          :date-options="dateOptions"
          :allow-edit="allowEdit"
          :has-custom-fields="hasCustomFields"
        ></product-contributors-card>
      </div>
    </div>
    <div v-if="display !== 'brief'" class="row avoid-break">
      <div class="col">
        <subjects-and-audience
          :product-id="productId"
          :subjects="product.getSubjects"
          :audiences="product.getAudiences"
          :audience-description="product.getAudienceDescription"
          :complexities="product.getComplexities"
          :safety="product.getSafety"
          :audience-ranges="product.getAudienceRanges"
          :display="display"
          :return-to="returnTo + encodeURIComponent('#subjects-and-audience')"
          :allow-edit="allowEdit"
          :has-custom-fields="hasCustomFields"
        ></subjects-and-audience>
      </div>
    </div>
    <div class="row avoid-break">
      <div class="col">
        <product-images-and-media
          :product-id="productId"
          :logon-id="logonId"
          :product-media="product.getResources.get"
          :media-types="mediaTypes"
          :media-formats="mediaFormats"
          :user-id-number="userIdNumber"
          :return-to="returnTo + encodeURIComponent('#images-and-media')"
          :share-media-modal-id="shareMediaModalId"
          :date-options="dateOptions"
          :allow-edit="allowEdit"
          :display="display"
          :has-custom-fields="hasCustomFields"
          data-v-step="biblio-detail-filedrop"
        ></product-images-and-media>
      </div>
    </div>
    <div v-if="hasCustomFields" class="row avoid-break">
      <div class="col">
        <b-card id="productCardCustomFields">
          <template #header>
            <h5 class="d-inline-block">Custom Fields</h5>
            <edit-icon
              v-if="$permitted('biblio/edit') && allowEdit"
              class="ml-2 d-print-none"
              :link="
                $baseUrl +
                '/biblio-edit/edit-notes/' +
                productId +
                (returnTo
                  ? '?return=' + returnTo + encodeURIComponent('#custom-fields')
                  : '')
              "
            ></edit-icon>
            <template v-if="customForm">
              <h5 class="d-inline-block">
                &emsp;(including
                {{ customForm }}
              </h5>
              <edit-icon
                v-if="$permitted('biblio/edit') && allowEdit"
                class="ml-2 d-print-none"
                :link="
                  $baseUrl +
                  '/biblio-edit/edit-custom/' +
                  productId +
                  (returnTo
                    ? '?return=' +
                      returnTo +
                      encodeURIComponent('#custom-fields')
                    : '')
                "
              ></edit-icon>
              <h5 class="d-inline-block">)</h5>
            </template>
            <product-quick-jump-dropdown
              class="float-right"
              :product-id="productId"
              :display="display"
              :has-custom-fields="hasCustomFields"
            ></product-quick-jump-dropdown>
          </template>
          <custom-fields
            :custom-fields="customFields"
            :date-options="dateOptions"
          ></custom-fields>
        </b-card>
      </div>
    </div>
    <div class="row avoid-break">
      <div class="col">
        <additional-notes
          :product-id="productId"
          :notes="product.getAdditionalNotes"
          :display="display"
          :return-to="returnTo + encodeURIComponent('#additional-notes')"
          :allow-edit="allowEdit"
          :has-custom-fields="hasCustomFields"
        ></additional-notes>
      </div>
    </div>
    <div v-if="display !== 'brief'" class="row avoid-break">
      <div class="col">
        <related-products
          :product-id="productId"
          :product="product"
          :products="product.getRelatedProducts.getAll"
          :display="display"
          :return-to="returnTo + encodeURIComponent('#related-products')"
          :date-options="dateOptions"
          :search-id="searchId"
          :page="page"
          :allow-edit="allowEdit"
          :has-custom-fields="hasCustomFields"
          data-v-step="biblio-detail-relatedproducts"
        ></related-products>
      </div>
    </div>
    <div class="row avoid-break">
      <div class="col">
        <product-formats
          :product-id="productId"
          :product="product"
          :date-options="dateOptions"
          :display="display"
          :return-to="returnTo + encodeURIComponent('#formats')"
          :allow-edit="allowEdit"
          :has-custom-fields="hasCustomFields"
          :logon-id="logonId"
          :search-id="searchId"
          :page="page"
        ></product-formats>
      </div>
    </div>
    <div class="row avoid-break">
      <div class="col">
        <full-text-descriptions
          :product-id="productId"
          :descriptions="product.getDescriptions.get"
          :display="display"
          :return-to="returnTo + encodeURIComponent('#full-text')"
          :allow-edit="allowEdit"
          :date-options="dateOptions"
          :has-custom-fields="hasCustomFields"
        ></full-text-descriptions>
      </div>
    </div>
    <div class="row avoid-break">
      <div class="col">
        <product-content-items
          :product-id="productId"
          :content-items="product.getContentItems.getAll"
          :return-to="returnTo + encodeURIComponent('#content-items')"
          :search-id="searchId"
          :page="page"
          :allow-edit="allowEdit"
          :has-custom-fields="hasCustomFields"
        ></product-content-items>
      </div>
    </div>
    <div v-if="display !== 'brief'" class="row avoid-break">
      <div class="col">
        <territorial-rights
          :product-id="productId"
          :product="product"
          :display="display"
          :return-to="returnTo + encodeURIComponent('#territorial-rights')"
          :allow-edit="allowEdit"
          :has-custom-fields="hasCustomFields"
        ></territorial-rights>
      </div>
    </div>
    <div class="row avoid-break">
      <div class="col">
        <suppliers-and-prices
          :product-id="productId"
          :suppliers="product.getSuppliers.get"
          :display="display"
          :return-to="returnTo + encodeURIComponent('#suppliers-and-prices')"
          :date-options="dateOptions"
          :allow-edit="allowEdit"
          :has-custom-fields="hasCustomFields"
        ></suppliers-and-prices>
      </div>
    </div>
    <div v-if="display !== 'brief'" class="row avoid-break">
      <div class="col">
        <market-representation
          :product-id="productId"
          :market-representatives="product.getMarketRepresentatives.get"
          :display="display"
          :return-to="returnTo + encodeURIComponent('#market-representation')"
          :date-options="dateOptions"
          :allow-edit="allowEdit"
          :has-custom-fields="hasCustomFields"
        ></market-representation>
      </div>
    </div>
    <div v-if="display !== 'brief'" class="row avoid-break">
      <div class="col">
        <prizes-and-promotions
          :product-id="productId"
          :prizes="product.getPrizes.get"
          :promotion="product.getPromotion"
          :display="display"
          :return-to="returnTo + encodeURIComponent('#prizes-and-promotions')"
          :logon-id="logonId"
          :share-media-modal-id="shareMediaModalId"
          :date-options="dateOptions"
          :allow-edit="allowEdit"
          :has-custom-fields="hasCustomFields"
        ></prizes-and-promotions>
      </div>
    </div>
    <div v-if="display !== 'brief'" class="row avoid-break">
      <div class="col">
        <product-contacts
          :product-id="productId"
          :product-contacts-group="product.getProductContactsGroup"
          :product-contacts="product.getProductContacts"
          :suppliers="product.getSuppliers.get"
          :return-to="returnTo + encodeURIComponent('#productcontacts')"
          :allow-edit="allowEdit"
          :has-custom-fields="hasCustomFields"
        ></product-contacts>
      </div>
    </div>
    <div class="row avoid-break">
      <div class="col">
        <product-status
          :status-object="product.getProductStatus"
          :legal-deposit-receipts="product.getLegalDepositReceipts"
          :product-id="productId"
          :display="display"
          :date-options="dateOptions"
        ></product-status>
      </div>
    </div>
    <div
      v-if="$permitted('biblio/edit') && !hideEditButtons && allowEdit"
      class="row avoid-break"
    >
      <div class="col">
        <product-edit-buttons
          :product-id="productId"
          :return-to="returnTo"
          :custom-form="customForm"
          :workflow-type="workflowType"
        ></product-edit-buttons>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Displays the content
 * of the detail tab for the detailed display
 * page
 */
import AssignedTags from "../tags/AssignedTags.vue";
import TitleAndIdentifiers from "./TitleAndIdentifiers.vue";
import ProductBibliographicInfo from "./ProductBibliographicInfo.vue";
import ProductMediaImg from "./ProductMediaImg.vue";
import ProductContributorsCard from "./ProductContributorsCard.vue";
import SubjectsAndAudience from "./SubjectsAndAudience.vue";
import ProductImagesAndMedia from "./ProductImagesAndMedia.vue";
import AdditionalNotes from "./AdditionalNotes.vue";
import RelatedProducts from "./RelatedProducts.vue";
import ProductStatus from "./ProductStatus.vue";
import ProductEditButtons from "./ProductEditButtons.vue";
import ProductFormats from "./ProductFormats.vue";
import FullTextDescriptions from "./FullTextDescriptions.vue";
import ProductContentItems from "./ProductContentItems.vue";
import PrizesAndPromotions from "./PrizesAndPromotions.vue";
import TerritorialRights from "./TerritorialRights.vue";
import SuppliersAndPrices from "./SuppliersAndPrices.vue";
import MarketRepresentation from "./MarketRepresentation.vue";
import ProductContacts from "./ProductContacts.vue";
import ProductQuickJumpDropdown from "./ProductQuickJumpDropdown.vue";
import CustomFields from "./CustomFields.vue";
import ParseDate from "../../mixins/parseDate.js";
import InfoIcon from "../icons/InfoIcon.vue";
import ProductStatusBadge from "./ProductStatusBadge.vue";
import ProductVisibilityBadge from "./ProductVisibilityBadge.vue";

export default {
  name: "BiblioDetailTab",

  components: {
    "title-and-identifiers": TitleAndIdentifiers,
    "product-media-img": ProductMediaImg,
    "product-contributors-card": ProductContributorsCard,
    "subjects-and-audience": SubjectsAndAudience,
    "product-images-and-media": ProductImagesAndMedia,
    "additional-notes": AdditionalNotes,
    "related-products": RelatedProducts,
    "product-status": ProductStatus,
    "product-edit-buttons": ProductEditButtons,
    "product-formats": ProductFormats,
    "full-text-descriptions": FullTextDescriptions,
    "product-content-items": ProductContentItems,
    "prizes-and-promotions": PrizesAndPromotions,
    "territorial-rights": TerritorialRights,
    "suppliers-and-prices": SuppliersAndPrices,
    "market-representation": MarketRepresentation,
    "product-contacts": ProductContacts,
    "assigned-tags": AssignedTags,
    "custom-fields": CustomFields,
    "product-bibliographic-info": ProductBibliographicInfo,
    "info-icon": InfoIcon,
    "product-quick-jump-dropdown": ProductQuickJumpDropdown,
    "product-status-badge": ProductStatusBadge,
    "product-visibility-badge": ProductVisibilityBadge,
  },

  mixins: [ParseDate],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      default: null,
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      default: null,
    },

    /**
     * Name of the search form where the search
     * was performed
     */
    searchForm: {
      type: String,
      default: null,
    },

    /**
     * The current user logon ID
     *
     * This is required for requests to the media manager API
     */
    logonId: {
      type: String,
      required: true,
    },

    /**
     * The logged in users ID Number
     *
     * This is required for requests to the media manager API
     */
    userIdNumber: {
      type: Number,
      required: true,
    },

    /**
     * Product data
     *
     * The data passed in must be an assosiative array (object in JS) of product data.
     * The keys of the array must match those defined in BooksonixProduct.php
     * Use the dump method to get an appropriate aray of product data
     */
    product: {
      type: Object,
      default: () => {},
    },

    /**
     * The list of media types available for product media
     *
     * Use BooksonixProductHelper getResourceTypes21
     */
    mediaTypes: {
      type: Array,
      default: () => [],
    },

    /**
     * The list of media formats available for product media
     *
     * Use BooksonixProductHelper getResourceFormats21
     */
    mediaFormats: {
      type: Array,
      default: () => [],
    },

    /**
     * List of tags that can be assigned to a product
     */
    tags: {
      type: Array,
      default: null,
    },

    /**
     * List of recipients with time that the product was
     * first sent to the recipient
     */
    accreditationStats: {
      type: Array,
      default: null,
    },

    /**
     * List of disseminations for the product
     */
    disseminationLog: {
      type: Array,
      default: null,
    },

    /**
     * Audit log
     */
    auditLog: {
      type: Array,
      default: null,
    },

    /**
     * Page number
     */
    page: {
      type: Number,
      default: null,
    },

    /**
     * Number of pages
     */
    pages: {
      type: Number,
      default: null,
    },

    /**
     * Return location for forms
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Status of dissemination
     * @values ERRORS, WARNINGS, null
     */
    disseminationErrorStatus: {
      type: String,
      default: null,
    },

    /**
     * Marked records object
     */
    markedRecords: {
      type: Object,
      default: null,
    },

    /**
     * Display setting for tab content
     * @values all, full, brief
     */
    display: {
      type: String,
      default: null,
    },

    /**
     * Unique ID of the media modal
     */
    shareMediaModalId: {
      type: String,
      default: null,
    },

    /**
     * Whether the product contains custom fields
     */
    hasCustomFields: {
      type: Boolean,
      default: false,
    },

    /**
     * This sets to hide edit buttons
     * This is used for the biblio output action
     * Should not be used to hide based on user permissions
     * instead use global $permitted()
     */
    hideEditButtons: {
      type: Boolean,
      default: false,
    },

    /**
     * List of products custom fields that display
     * in the notes section
     */
    customFields: {
      type: Array,
      default: null,
    },

    /**
     * Flag to identify whether this product is of
     * the type Electronic book text
     */
    isEbook: {
      type: Boolean,
      default: false,
    },

    /**
     * Custom form exists and its title
     */
    customForm: {
      type: String,
      default: null,
    },

    /**
     * What type of workflow is in use. C for Cake and L for legacy.
     */
    workflowType: {
      type: String,
      default: "",
    },
  },

  computed: {
    recordMarked: {
      get: function () {
        // read whether the current productId is in marked records
        if (!this.markedRecords) {
          return false;
        }
        const markedRecords = this.markedRecords.getAll();
        return markedRecords.includes(this.productId);
      },
      set: function (val) {
        // mark / unmark the current product ID
        if (this.markedRecords) {
          this.markedRecords.setMarked(this.productId, val);
        }
      },
    },

    allowEdit: function () {
      if (!this.product) {
        return false;
      }
      return this.product.getProductStatus.getProductStatus != "D";
    },
  },
};
</script>

<style scoped>
.avoid-break {
  break-inside: avoid;
}
@media print {
  @page {
    margin-top: 20px;
    margin-bottom: 25px;
  }
}
</style>
