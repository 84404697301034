<template>
  <div v-if="feedDetails">
    <div
      v-if="
        feedDetails.autoSuspended == 'Y' || feedDetails.autoSuspended == 'YY'
      "
      class="alert alert-warning message error"
    >
      <b>Warning:</b> Automatic feeds to {{ feedRecipientName }} are temporarily
      suspended on this server (except possibly for other application(s))
    </div>
    <div
      v-else-if="feedDetails.autoSuspended == 'YN'"
      class="alert alert-warning message error"
    >
      <b>Warning:</b> Automatic feeds to {{ feedRecipientName }} are temporarily
      suspended on this server except for this application (and maybe others)
    </div>
    <div
      v-else-if="feedDetails.autoSuspended == 'NY'"
      class="alert alert-warning message error"
    >
      <b>Warning:</b> Automatic feeds to {{ feedRecipientName }} are temporarily
      suspended for this application
    </div>
    <div class="row">
      <div class="col-12">
        <b>
          Settings for the {{ feedRecipientGroup }} &raquo;
          {{ feedRecipientName }} feed
        </b>
        <span class="feed-codename" title="Feed code name">
          [{{ feedDetails.codeName }}]
        </span>
        <span title="Feed recipient_id">[#{{ feedRecipientId }}]</span>
      </div>
    </div>
    <br />
    <b-tabs
      v-model="currentTab"
      @input="
        (tabIndex) => {
          $emit('tab-change', tabIndex);
        }
      "
    >
      <b-tab title="Status &amp; Schedule">
        <!-- status -->
        <div class="row">
          <div class="col-4">
            <label :for="'feed' + feedRecipientId + '-enabled'">
              Enabled:&nbsp;
            </label>
            <select
              :id="'feed' + feedRecipientId + '-enabled'"
              v-model="feedDetails.enabled"
              @change="feedModified"
            >
              <optgroup label="No">
                <option value="Nnotrequired">No &ndash; not required</option>
                <option value="Nwaitingdetails">
                  No &ndash; waiting for details
                </option>
                <option value="Nfailingfeed">No &ndash; failing feed</option>
                <option value="Ndeadfeed">No &ndash; dead feed</option>
                <option value="Nsuspended_client">
                  No &ndash; suspended by client
                </option>
                <option value="Nsuspended_recipient">
                  No &ndash; suspended by recipient
                </option>
                <option value="Nunlisted">
                  No &ndash; unlisted (inactive)
                </option>
                <option value="Nother">No &ndash; other</option>
              </optgroup>
              <optgroup label="Yes">
                <option value="Ywaitingdetails">
                  Yes &ndash; waiting for details
                </option>
                <option value="Ytesting">Yes &ndash; testing</option>
                <option value="Ylive">Yes &ndash; live</option>
                <option value="Ylive_externalschedule">
                  Yes &ndash; live (externally scheduled)
                </option>
                <option value="Yfailingfeed">Yes &ndash; failing feed</option>
                <option value="Ymanual_only">Yes &ndash; manual only</option>
                <option value="Yother">Yes &ndash; other</option>
              </optgroup>
            </select>
          </div>
          <div class="col-4">
            <label :for="'feed' + feedRecipientId + '-altname'">
              Alternate name:&nbsp;
            </label>
            <input
              :id="'feed' + feedRecipientId + '-altname'"
              v-model="feedDetails.altName"
              class="feed-altname"
              type="text"
              placeholder="Alternate name for this feed"
              maxlength="100"
              @input="altNameModified"
            />
          </div>
          <div class="col-3">
            <label>Original name:&nbsp;{{ feedDetails.origName }}</label>
          </div>
        </div>
        <hr />
        <!-- schedule -->
        <feed-schedule
          :controls-id-prefix="'feed' + feedRecipientId + 'schedule-m'"
          which="modified"
          :enabled="feedDetails.autoEnabled"
          :schedule="feedDetails.autoSchedule"
          :next-run="feedDetails.autoNextrun"
          :last-run="feedDetails.lastrunAuto"
          @change="
            (enabled, schedule, nextRun) => {
              feedDetails.autoEnabled = enabled;
              feedDetails.autoSchedule = schedule;
              feedDetails.autoNextrun = nextRun;
              feedModified();
            }
          "
        />
        <feed-schedule
          :controls-id-prefix="'feed' + feedRecipientId + 'schedule-a'"
          which="all"
          :enabled="feedDetails.autofullEnabled"
          :schedule="feedDetails.autofullSchedule"
          :next-run="feedDetails.autofullNextrun"
          :last-run="feedDetails.lastrunAutofull"
          @change="
            (enabled, schedule, nextRun) => {
              feedDetails.autofullEnabled = enabled;
              feedDetails.autofullSchedule = schedule;
              feedDetails.autofullNextrun = nextRun;
              feedModified();
            }
          "
        />
        <hr />
        <!-- notes -->
        <div class="row">
          <div class="col-12">
            <label :for="'feed' + feedRecipientId + '-notes'">Notes</label>
            <br />
            <textarea
              :id="'feed' + feedRecipientId + '-notes'"
              v-model="feedDetails.notes"
              class="notes"
              @input="feedModified"
            />
          </div>
        </div>
      </b-tab>
      <!-- notification -->
      <b-tab title="Notification">
        <div class="row">
          <div class="col-12">
            <input
              :id="'feed' + feedRecipientId + 'notify'"
              v-model="feedDetails.notify.enabled"
              type="checkbox"
              @change="feedModified"
            />
            &nbsp;
            <label :for="'feed' + feedRecipientId + 'notify'">
              When BooksoniX disseminates records to {{ feedDetails.origName }},
              an email for them will be:
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            &emsp;&nbsp;&nbsp;
            <label
              :id="'feed' + feedRecipientId + '-notify-to'"
              class="feed-notify-to"
            >
              Sent to:
            </label>
          </div>
          <div class="col-10">
            <input
              :id="'feed' + feedRecipientId + '-notify-to'"
              v-model="feedDetails.notify.to"
              type="text"
              class="feed-notify-to"
              :pattern="emailListPattern"
              placeholder="Recipient notification emails are sent to these adddress(es)"
              @input="feedModifiedCheck"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            &emsp;&nbsp;&nbsp;
            <label
              :id="'feed' + feedRecipientId + '-notify-cc'"
              class="feed-notify-to"
            >
              Copied to:
            </label>
          </div>
          <div class="col-10">
            <input
              :id="'feed' + feedRecipientId + '-notify-cc'"
              v-model="feedDetails.notify.cc"
              type="text"
              class="feed-notify-to"
              :pattern="emailListPattern"
              placeholder="Recipient notification emails are copied to these adddress(es)"
              @input="feedModifiedCheck"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            &emsp;&nbsp;&nbsp;
            <input
              :id="'feed' + feedRecipientId + '-notify-nodata'"
              v-model="feedDetails.notify.noData"
              type="checkbox"
              @change="feedModified"
            />
            <label :for="'feed' + feedRecipientId + '-notify-nodata'">
              &nbsp;Send an email even if no data is sent.
            </label>
          </div>
        </div>
      </b-tab>
      <!-- records -->
      <b-tab title="Records">
        <div class="row">
          <div class="col-2">
            <label>Records:</label>
          </div>
          <div class="col-4">
            <input
              :id="'feed' + feedRecipientId + '-records-M'"
              v-model="feedDetails.records"
              type="radio"
              :name="'feed' + feedRecipientId + '-records'"
              value="M"
              @change="feedModified"
            />
            <label :for="'feed' + feedRecipientId + '-records-M'">
              &nbsp;Modified/Approved since last sent
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-2"></div>
          <div class="col-4">
            <input
              :id="'feed' + feedRecipientId + '-records-A'"
              v-model="feedDetails.records"
              type="radio"
              :name="'feed' + feedRecipientId + '-records'"
              value="A"
              @change="feedModified"
            />
            <label :for="'feed' + feedRecipientId + '-records-A'">
              &nbsp;All
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-2"></div>
          <div class="col-4">
            <input
              :id="'feed' + feedRecipientId + '-records-AM'"
              v-model="feedDetails.records"
              type="radio"
              :name="'feed' + feedRecipientId + '-records'"
              value="AM"
              @change="feedModified"
            />
            <label :for="'feed' + feedRecipientId + '-records-AM'">
              &nbsp;All next time, Modified/Approved thereafter
            </label>
          </div>
        </div>
      </b-tab>
      <!-- requirements -->
      <b-tab title="Requirements">
        <data-requirements
          :feed-recipient-id="feedRecipientId"
          :data-requirements="feedDetails.dataRequirements"
          :default-requirement-codes="feedDetails.defaultRequirementCodes"
          :return-condition-types="returnConditionTypes"
          :languages="languages"
          :countries="countries"
          :formats="formats"
          :global-tags="globalTags"
          @change="
            (newRequirements) => {
              feedDetails.dataRequirements = newRequirements;
              dataErrors &= ~4;
              feedModified();
            }
          "
          @change-clearstore="clearStore = true"
          @data-errors="
            dataErrors |= 4;
            feedModified();
          "
        />
      </b-tab>
      <!-- data settings -->
      <b-tab title="Data Settings">
        <data-settings
          :feed-recipient-id="feedRecipientId"
          :data-settings="feedDetails.dataSettings"
          :languages="languages"
          :countries="countries"
          :currencies="currencies"
          :formats="formats"
          :price-types="priceTypes"
          :discount-schemes="discountSchemes"
          @change="
            (newSettings) => {
              feedDetails.dataSettings = newSettings;
              dataErrors &= ~8;
              feedModified();
            }
          "
          @change-clearstore="clearStore = true"
          @data-errors="
            dataErrors |= 8;
            feedModified();
          "
        />
      </b-tab>
      <!-- media settings -->
      <b-tab title="Media Settings">
        <media-settings
          :feed-recipient-id="feedRecipientId"
          :media-settings="feedDetails.mediaSettings"
          @change="
            (newSettings) => {
              feedDetails.mediaSettings = newSettings;
              clearStore = true;
              feedModified();
            }
          "
        />
      </b-tab>
      <!-- transfer -->
      <b-tab title="Transfer">
        <feed-xfer
          :feed-recipient-id="feedRecipientId"
          :feed-recipient-name="feedRecipientName"
          :data-xfer="feedDetails.dataXfer"
          :media-xfer="feedDetails.mediaXfer"
          :default-data-xfer-options="feedDetails.defaultDataXferOptions"
          :default-media-xfer-options="feedDetails.defaultMediaXferOptions"
          :data-formats="dataFormats"
          @change="
            (newDataXfer, newMediaXfer) => {
              feedDetails.dataXfer = newDataXfer;
              feedDetails.mediaXfer = newMediaXfer;
              dataErrors &= ~16;
              feedModified();
            }
          "
          @data-errors="
            dataErrors |= 16;
            feedModified();
          "
        />
      </b-tab>
    </b-tabs>
    <!-- buttons -->
    <div class="row">
      <div class="col-5 mt-2 pr-0">
        <button
          :id="'feed' + feedRecipientId + '-save'"
          class="btn"
          :class="{
            'btn-success': modified,
            'btn-secondary': !modified,
            disabled: readOnly || !modified,
          }"
          @click="saveChanges"
        >
          Save
        </button>
        <button class="btn btn-padleft btn-danger" @click="discardChanges">
          Discard
        </button>
        <b-dropdown
          dropup
          variant="warning"
          text="Suspend this feed for all applications"
          :title="
            'Suspend automatic feeds to ' +
            feedRecipientName +
            ' for all applications on this server'
          "
          :disabled="readOnly"
          class="btn-padleft4"
        >
          <b-dropdown-item
            :title="
              'Suspend automatic feeds to ' +
              feedRecipientName +
              ' for all applications on this server'
            "
            :disabled="readOnly || feedDetails.autoSuspended == 'Y'"
            @click="suspendAll"
          >
            Suspend this feeds for all applications
          </b-dropdown-item>
          <b-dropdown-item
            :title="
              'Suspend automatic feeds to ' +
              feedRecipientName +
              ' for all applications on this server except this one'
            "
            :disabled="readOnly || feedDetails.autoSuspended == 'YN'"
            @click="suspendAllExcept"
          >
            Suspend this feed for all applications except self
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item
            :title="
              'Resume automatic feeds to ' +
              feedRecipientName +
              ' for all applications on this server'
            "
            :disabled="readOnly || feedDetails.autoSuspended == 'N'"
            @click="resumeAll"
          >
            Resume this feed for all applications
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="col-5 mt-2">
        <input
          :id="'feed' + feedRecipientId + 'clearstore'"
          v-model="clearStore"
          type="checkbox"
        />
        <label :for="'feed' + feedRecipientId + 'clearstore'">
          &nbsp;Clear stored dissemination record checks results for this feed
          <info-icon
            title="Clear stored dissemination checks results"
            text="Dissemination stores the results of feed record checks to allow dissemination to run faster. The changes you've made might mean that different results could be generated. If this is the case then the stored record checks results for this feed must be cleared. However clearing them unnecessarily will probably slow down dissemination."
          />
        </label>
      </div>
      <div class="col-2 mt-2">
        <save-indicator
          :status="saveStatus"
          complete-message="Saved changes"
          @status-cleared="saveStatus = null"
        />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Displays the dissemination settings feed component
 */
import FeedSchedule from "./FeedSchedule.vue";
import DataRequirements from "./DataRequirements.vue";
import DataSettings from "./DataSettings.vue";
import MediaSettings from "./MediaSettings.vue";
import FeedXfer from "./FeedXfer.vue";
import InfoIcon from "../../icons/InfoIcon.vue";
import SaveIndicator from "../../ui/SaveIndicator.vue";
import { HTTP } from "../../../http-common.js";

export default {
  name: "DisseminationSettingsFeed",

  components: {
    "feed-schedule": FeedSchedule,
    "data-requirements": DataRequirements,
    "data-settings": DataSettings,
    "media-settings": MediaSettings,
    "feed-xfer": FeedXfer,
    "info-icon": InfoIcon,
    "save-indicator": SaveIndicator,
  },

  props: {
    /**
     * Feed recipient id
     */
    feedRecipientId: {
      type: Number,
      required: true,
    },

    /**
     * Feed recipient name
     */
    feedRecipientName: {
      type: String,
      required: true,
    },

    /**
     * Feed recipient group
     */
    feedRecipientGroup: {
      type: String,
      required: true,
    },

    /**
     * Data formats
     */
    dataFormats: {
      type: Object,
      default: null,
    },

    /**
     * List of all return condition types
     */
    returnConditionTypes: {
      type: Array,
      required: true,
    },

    /**
     * List of all languages
     */
    languages: {
      type: Array,
      required: true,
    },

    /**
     * List of all countries
     */
    countries: {
      type: Array,
      required: true,
    },

    /**
     * List of all formats
     */
    formats: {
      type: Array,
      required: true,
    },

    /**
     * List of all currencies
     */
    currencies: {
      type: Array,
      required: true,
    },

    /**
     * List of all price types
     */
    priceTypes: {
      type: Array,
      required: true,
    },

    /**
     * List of all discount schemes
     */
    discountSchemes: {
      type: Array,
      required: true,
    },

    /**
     * List of all global tags
     */
    globalTags: {
      type: Array,
      required: true,
    },

    /**
     * Default tab
     */
    defaultTab: {
      type: Number,
      default: 0,
    },

    /**
     * Read-only
     */
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      feedDetails: null,
      modified: false,
      dataErrors: 0,
      clearStore: false,
      saveStatus: null,
      currentTab: this.defaultTab,
    };
  },

  computed: {
    emailListPattern: function () {
      // reg exp pattern for an email address list
      // see SO question 429 for the structure of this pattern
      // note: in a browser and within a regex character class, forward slash, hyphen, left brace, right brace and pipe must be escaped
      return "\\s*(([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-][A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~. \\t\\n\\r\\-]*|\"([^\"\\\\]|\\\\[!-~])*\")?\\s*<([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\"([^\"\\\\]|\\\\[!-~])*\")@([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\\[\\s*[!-Z^-~]*\\s*\\])>|([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\"([^\"\\\\]|\\\\[!-~])*\")@([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\\[\\s*[!-Z^-~]*\\s*\\]))(\\s*,\\s*(([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-][A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~. \\t\\n\\r\\-]*|\"([^\"\\\\]|\\\\[!-~])*\")?\\s*<([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\"([^\"\\\\]|\\\\[!-~])*\")@([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\\[\\s*[!-Z^-~]*\\s*\\])>|([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\"([^\"\\\\]|\\\\[!-~])*\")@([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\\[\\s*[!-Z^-~]*\\s*\\])))*\\s*";
    },
    emailAddrPattern: function () {
      // reg exp pattern for a single email address which is used when extracting email addresses
      // see SO question 429 for the structure of this pattern
      // note: in a browser and within a regex character class, forward slash, hyphen, left brace, right brace and pipe must be escaped
      return "\\s*(([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-][A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~. \\t\\n\\r\\-]*|\"([^\"\\\\]|\\\\[!-~])*\")?\\s*<([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\"([^\"\\\\]|\\\\[!-~])*\")@([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\\[\\s*[!-Z^-~]*\\s*\\])>|([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\"([^\"\\\\]|\\\\[!-~])*\")@([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]([A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~.\\-]*[A-Za-z0-9!#$%&'*+\\/=?^_`\\{\\|\\}~\\-])?|\\[\\s*[!-Z^-~]*\\s*\\]))\\s*";
    },
  },

  mounted: function () {
    this.getDetails();
  },

  methods: {
    /**
     * Get the details
     */
    getDetails: function () {
      let url =
        this.$baseUrl +
        "customisation/dissemination-settings/feed/" +
        this.feedRecipientId;

      HTTP.get(url)
        .then((response) => {
          let feedDetails = response.data;
          if (feedDetails.notify) {
            if (feedDetails.notify.to) {
              if (typeof feedDetails.notify.to == "object") {
                feedDetails.notify.to = feedDetails.notify.to.join(", ");
              }
            } else {
              feedDetails.notify.to = "";
            }
            if (feedDetails.notify.cc) {
              if (typeof feedDetails.notify.cc == "object") {
                feedDetails.notify.cc = feedDetails.notify.cc.join(", ");
              }
            } else {
              feedDetails.notify.cc = "";
            }
            feedDetails.notify.noData =
              "exceptNoData" in feedDetails.notify
                ? !feedDetails.notify.exceptNoData
                : true;
          } else {
            feedDetails.notify = {
              enabled: false,
              to: "",
              cc: "",
              noData: true,
            };
          }
          if (feedDetails.altName === null) {
            feedDetails.altName = "";
          }
          feedDetails.origAltName = feedDetails.altName;
          this.feedDetails = feedDetails;
        })
        .catch(() => {
          this.$bvModal.msgBoxOk("Unable to retrieve this feed's details");
        });
    },

    /**
     * Feed has been modified
     */
    feedModified: function () {
      this.modified = true;
      this.$emit("change");
    },

    /**
     * Feed alternate name has been modified
     */
    altNameModified: function () {
      this.modified = true;
      this.$emit(
        "name-change",
        this.feedRecipientId,
        this.feedDetails.altName.length != 0
          ? this.feedDetails.altName
          : this.feedDetails.origName
      );
      this.$emit("change");
    },

    /**
     * Feed has been modified and we validate certain fields
     */
    feedModifiedCheck: function () {
      // check if the notification email address lists are valid
      let listPattern = new RegExp("^(" + this.emailListPattern + ")?$", "g");
      if (this.feedDetails.notify.to.search(listPattern) == -1) {
        this.dataErrors |= 1;
      } else {
        this.dataErrors &= ~1;
      }

      if (this.feedDetails.notify.cc.search(listPattern) == -1) {
        this.dataErrors |= 2;
      } else {
        this.dataErrors &= ~2;
      }

      this.feedModified();
    },

    /**
     * Save changes
     */
    saveChanges: function () {
      if (this.readOnly || !this.modified) {
        return;
      }

      // check if the settings are valid
      if (this.dataErrors != 0) {
        // notification to
        if ((this.dataErrors & 1) != 0) {
          this.$bvModal.msgBoxOk(
            "The notification sent to recipients list is invalid, please correct"
          );
          return;
        }
        // notification cc
        if ((this.dataErrors & 2) != 0) {
          this.$bvModal.msgBoxOk(
            "The notification copied to recipients list is invalid, please correct"
          );
          return;
        }
        // requirements
        if ((this.dataErrors & 4) != 0) {
          this.$bvModal.msgBoxOk(
            "There are errors in the Requirements tab, please correct"
          );
          return;
        }
        // data settings
        if ((this.dataErrors & 8) != 0) {
          this.$bvModal.msgBoxOk(
            "There are errors in the Data Settings tab, please correct"
          );
          return;
        }
        // requirements
        if ((this.dataErrors & 16) != 0) {
          this.$bvModal.msgBoxOk(
            "There are errors in the Transfer tab, please correct"
          );
          return;
        }

        // unkown
        this.$bvModal.msgBoxOk(
          "Please correct the errors in the feed's settings"
        );
        return;
      }

      // build save data
      let newFeedDetails = {
        id: this.feedRecipientId,
        enabled: this.feedDetails.enabled,
        autoEnabled: this.feedDetails.autoEnabled,
        autoSchedule: this.feedDetails.autoSchedule,
        autoNextrun: this.feedDetails.autoNextrun,
        autofullEnabled: this.feedDetails.autofullEnabled,
        autofullSchedule: this.feedDetails.autofullSchedule,
        autofullNextrun: this.feedDetails.autofullNextrun,
        notify: {
          enabled: this.feedDetails.notify.enabled,
          to: [],
          cc: [],
          exceptNoData: !this.feedDetails.notify.noData,
        },
        records: this.feedDetails.records,
        dataRequirements: this.feedDetails.dataRequirements,
        dataSettings: this.feedDetails.dataSettings,
        mediaSettings: this.feedDetails.mediaSettings,
        dataXfer: this.feedDetails.dataXfer,
        mediaXfer: this.feedDetails.mediaXfer,
        altName:
          this.feedDetails.altName != "" ? this.feedDetails.altName : null,
        notes: this.feedDetails.notes != "" ? this.feedDetails.notes : null,
        fk: {
          imprints: [],
          publishers: [],
          suppliers: [],
          series: [],
          tags: [],
        },
        clearStore: this.clearStore,
      };

      let addrPattern = new RegExp(this.emailAddrPattern, "g");
      let addr;
      while ((addr = addrPattern.exec(this.feedDetails.notify.to)) !== null) {
        newFeedDetails.notify.to.push(addr[1]);
      }
      addrPattern.lastIndex = 0;
      while ((addr = addrPattern.exec(this.feedDetails.notify.cc)) !== null) {
        newFeedDetails.notify.cc.push(addr[1]);
      }
      for (let prop in this.feedDetails.notify) {
        if (prop.search(/enabled|to|cc|noData/) == -1) {
          newFeedDetails.notify[prop] = this.feedDetails.notify[prop];
        }
      }

      // populate the fk field
      if (newFeedDetails.dataRequirements) {
        if ("imprints" in newFeedDetails.dataRequirements) {
          for (const index in newFeedDetails.dataRequirements.imprints) {
            let imprintId = newFeedDetails.dataRequirements.imprints[index];
            if (typeof imprintId == "number") {
              newFeedDetails.fk.imprints.push(imprintId);
            }
          }
        }
        if ("publishers" in newFeedDetails.dataRequirements) {
          for (const index in newFeedDetails.dataRequirements.publishers) {
            let publisherId = newFeedDetails.dataRequirements.publishers[index];
            if (typeof publisherId == "number") {
              newFeedDetails.fk.publishers.push(publisherId);
            }
          }
        }
        if ("suppliers" in newFeedDetails.dataRequirements) {
          for (const index in newFeedDetails.dataRequirements.suppliers) {
            let supplierId = newFeedDetails.dataRequirements.suppliers[index];
            if (typeof supplierId == "number") {
              newFeedDetails.fk.suppliers.push(supplierId);
            }
          }
        }
        if ("series" in newFeedDetails.dataRequirements) {
          for (const index in newFeedDetails.dataRequirements.series) {
            let seriesId = newFeedDetails.dataRequirements.series[index];
            if (typeof seriesId == "number") {
              newFeedDetails.fk.series.push(seriesId);
            }
          }
        }
        if ("tags" in newFeedDetails.dataRequirements) {
          for (const index in newFeedDetails.dataRequirements.tags) {
            let tagId = newFeedDetails.dataRequirements.tags[index];
            if (typeof tagId == "number") {
              newFeedDetails.fk.tags.push(tagId);
            }
          }
        }
      }

      if (newFeedDetails.dataSettings) {
        let dsConditional = [
          "imprintIdentifiers",
          "publisherIdentifiers",
          "proprietarySubjects",
          "supplierIdentifiers",
          "distributorDiscounts",
          "distributorDiscountCodes",
        ];
        for (let propIndex in dsConditional) {
          let propName = dsConditional[propIndex];
          if (propName in newFeedDetails.dataSettings) {
            for (const index in newFeedDetails.dataSettings[propName]) {
              if ("condition" in newFeedDetails.dataSettings[propName][index]) {
                let condition =
                  newFeedDetails.dataSettings[propName][index].condition;
                if (typeof condition.value == "number") {
                  switch (condition.type) {
                    case "IMPRINT":
                      if (
                        newFeedDetails.fk.imprints.indexOf(condition.value) ==
                        -1
                      ) {
                        newFeedDetails.fk.imprints.push(condition.value);
                      }
                      break;
                    case "PUBLISHER":
                      if (
                        newFeedDetails.fk.publishers.indexOf(condition.value) ==
                        -1
                      ) {
                        newFeedDetails.fk.publishers.push(condition.value);
                      }
                      break;
                  }
                }
              }
            }
          }
        }

        let dsSuppliers1 = [
          "supplierIdentifiers",
          "distributorDiscounts",
          "distributorDiscountCodes",
        ];
        for (let propIndex in dsSuppliers1) {
          let propName = dsSuppliers1[propIndex];
          if (propName in newFeedDetails.dataSettings) {
            for (const index in newFeedDetails.dataSettings[propName]) {
              if ("supplier" in newFeedDetails.dataSettings[propName][index]) {
                let supplierId =
                  newFeedDetails.dataSettings[propName][index].supplier;
                if (
                  typeof supplierId == "number" &&
                  newFeedDetails.fk.suppliers.indexOf(supplierId) == -1
                ) {
                  newFeedDetails.fk.suppliers.push(supplierId);
                }
              }
            }
          }
        }

        let dsSuppliers2 = [
          "includedSuppliers",
          "includedMarketRepresentatives",
        ];
        for (let propIndex in dsSuppliers2) {
          let propName = dsSuppliers2[propIndex];
          if (propName in newFeedDetails.dataSettings) {
            for (const index in newFeedDetails.dataSettings[propName]) {
              let supplierId = newFeedDetails.dataSettings[propName][index];
              if (
                typeof supplierId == "number" &&
                newFeedDetails.fk.suppliers.indexOf(supplierId) == -1
              ) {
                newFeedDetails.fk.suppliers.push(supplierId);
              }
            }
          }
        }

        if ("alternatePubDate" in newFeedDetails.dataSettings) {
          if ("supplier" in newFeedDetails.dataSettings.alternatePubDate) {
            newFeedDetails.fk.suppliers.push(
              newFeedDetails.dataSettings.alternatePubDate.supplier
            );
          } else if (
            "marketRepresentative" in
            newFeedDetails.dataSettings.alternatePubDate
          ) {
            newFeedDetails.fk.suppliers.push(
              newFeedDetails.dataSettings.alternatePubDate.marketRepresentative
            );
          }
        }
      }

      // save the changes
      let url = this.$baseUrl + "customisation/dissemination-settings";
      let postData = new URLSearchParams();
      postData.append("feed", JSON.stringify(newFeedDetails));

      this.saveStatus = "saving";
      HTTP.post(url, postData)
        .then(() => {
          // changes have been saved
          this.feedDetails.origAltName = this.feedDetails.altName;
          this.modified = false;
          this.saveStatus = "saved";
          this.$emit("save");
        })
        .catch(() => {
          this.saveStatus = null;
          this.$bvModal.msgBoxOk(
            "An error has occurred while saving your feed's settings"
          );
        });
    },

    /** Discard the current feed's change */
    discardChanges: function () {
      this.feedDetails.altName = this.feedDetails.origAltName;
      this.$emit(
        "name-change",
        this.feedRecipientId,
        this.feedDetails.altName.length != 0
          ? this.feedDetails.altName
          : this.feedDetails.origName
      );
      this.$emit("close");
    },

    /**
     * Suspend all feeds to this recipient on this server
     */
    suspendAll: function () {
      if (!this.readOnly && this.feedDetails.autoSuspended != "Y") {
        this.$bvModal
          .msgBoxConfirm(
            "Are you sure you want to suspend automatic feeds to " +
              this.feedRecipientName +
              " for all applications on this server?"
          )
          .then((sure) => {
            if (sure) {
              let url = this.$baseUrl + "customisation/dissemination-settings";
              let postData = new URLSearchParams(
                "feedServerAutoSuspend=" + this.feedRecipientId
              );

              this.saveStatus = "saving";
              HTTP.post(url, postData)
                .then(() => {
                  this.saveStatus = "saved";
                  this.feedDetails.autoSuspended = "Y";
                })
                .catch(() => {
                  this.saveStatus = null;
                  this.$bvModal.msgBoxOk(
                    "An error has occurred while suspending automatic feeds to " +
                      this.feedRecipientName +
                      " for all applications on this server"
                  );
                });
            }
          });
      }
    },

    /**
     * Suspend all feeds to this recipient on this server except this application
     */
    suspendAllExcept: function () {
      if (!this.readOnly && this.feedDetails.autoSuspended != "YN") {
        this.$bvModal
          .msgBoxConfirm(
            "Are you sure you want to suspend automatic feeds to " +
              this.feedRecipientName +
              " for all applications on this server except this one?"
          )
          .then((sure) => {
            if (sure) {
              let url = this.$baseUrl + "customisation/dissemination-settings";
              let postData = new URLSearchParams(
                "feedServerAutoSuspendExcept=" + this.feedRecipientId
              );

              this.saveStatus = "saving";
              HTTP.post(url, postData)
                .then(() => {
                  this.saveStatus = "saved";
                  this.feedDetails.autoSuspended = "YN";
                })
                .catch(() => {
                  this.saveStatus = null;
                  this.$bvModal.msgBoxOk(
                    "An error has occurred while suspending automatic feeds to " +
                      this.feedRecipientName +
                      " for all applications on this server except this one"
                  );
                });
            }
          });
      }
    },

    /**
     * Resume all feeds to this recipient on this server
     */
    resumeAll: function () {
      if (!this.readOnly && this.feedDetails.autoSuspended != "N") {
        this.$bvModal
          .msgBoxConfirm(
            "Are you sure you want to resume automatic feeds to " +
              this.feedRecipientName +
              " for all applications on this server?"
          )
          .then((sure) => {
            if (sure) {
              let url = this.$baseUrl + "customisation/dissemination-settings";
              let postData = new URLSearchParams(
                "feedServerAutoResume=" + this.feedRecipientId
              );

              this.saveStatus = "saving";
              HTTP.post(url, postData)
                .then(() => {
                  this.saveStatus = "saved";
                  this.feedDetails.autoSuspended = "N";
                })
                .catch(() => {
                  this.saveStatus = null;
                  this.$bvModal.msgBoxOk(
                    "An error has occurred while resuming automatic feeds to " +
                      this.feedRecipientName +
                      " for all applications on this server"
                  );
                });
            }
          });
      }
    },
  },
};
</script>

<style scoped>
.btn-padleft {
  margin-left: 1rem;
}
.btn-padleft4 {
  margin-left: 4rem;
}
span.feed-codename {
  padding-left: 0.4rem;
}
.feed-altname {
  width: 16rem;
}
label.feed-notify-to {
  padding-top: 3px;
}
input.feed-notify-to {
  width: 90%;
}
input.feed-notify-to:invalid {
  border: red solid 3px;
}
textarea.notes {
  width: 100%;
  height: 100px;
}
</style>
