<template>
  <div v-if="(!contactId || !contact) && !deleted">
    <div class="row">
      <div class="col">
        <base-header>Contact not found</base-header>
        <p>
          Unfortunately your search strategy did not match any records in the
          database.
        </p>
        <p>
          Please <a :href="$baseUrl + 'menus/contacts'">click here</a> to return
          to the Search Form so that you can try again.
        </p>
      </div>
    </div>
  </div>
  <div v-else-if="contact && $permitted('contacts/view')">
    <div
      v-if="searchId !== null && page !== null && pages !== null && !printMode"
      class="row"
    >
      <div class="col">
        <div>
          <contacts-navigationbar
            class="mt-2"
            action="detailed/0"
            :search-id="searchId"
            :page="page"
            :pages="pages"
            :format="format"
            page-label="Record"
          ></contacts-navigationbar>
        </div>
      </div>
    </div>
    <div v-if="!printMode" class="row">
      <div class="col">
        <div class="mt-2">
          <contacts-menubar
            location="contact-detail"
            :search-id="searchId"
            :search-form="searchForm"
            :has-search-form="hasSearchForm"
            :marked-records="markedRecords"
            :marked-records-actions-enabled="true"
            :contact-id="contactId"
            :use-page-refresh="false"
            :page="page"
            :format="format"
            :contact-deleted="contactDeleted"
            data-v-step="contact-detail-menubar"
            @markclick.prevent="markClicked"
          ></contacts-menubar>
        </div>
      </div>
    </div>
    <div v-if="contactDeleted" class="row">
      <div class="col">
        <b-card
          header="Information - Record Deleted"
          border-variant="info"
          header-border-variant="info"
        >
          <div class="p-1">This record has been deleted</div>
        </b-card>
      </div>
    </div>
    <div v-else class="row">
      <div class="col">
        <b-card class="mt-2">
          <h2>
            <i
              class="fa-solid"
              :class="{
                'fa-user': !isCorporation,
                'fa-building': isCorporation,
              }"
            ></i>
            {{ contact.DisplayName }}
          </h2>
          <div v-if="markedRecords && !printMode" class="row my-2">
            <div class="col">
              <b-form-checkbox
                id="markedCheckbox"
                v-model="recordMarked"
                name="markedCheckbox"
                :value="true"
                :unchecked-value="false"
              >
                Mark record
                <info-icon tip="markedRecordsContacts"></info-icon>
              </b-form-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <assigned-tags
                ref="contactTags"
                :record-id="contactId"
                record-type="contact"
                :tags="contact.Tags"
                :all-tags="tags"
                :search-id="searchId"
                :position="page"
                :allow-edit="!printMode"
              ></assigned-tags>
            </div>
          </div>
          <div class="row avoid-break">
            <div class="col-8">
              <contact-descriptive-list-general
                class="mt-4"
                :contact-id="contactId"
                :contact="contact"
                :return-to="returnTo + encodeURIComponent('#general')"
                :date-options="contactDateOptions"
                :search-id="searchId"
                :page="page"
                :allow-edit="!printMode"
                data-v-step="contact-detail-cards"
              ></contact-descriptive-list-general>
            </div>
            <div class="col-4">
              <div class="row">
                <div class="col">
                  <contact-image
                    :contact="contact"
                    :logon-id="logonId"
                    :share-media-modal-id="shareMediaModalId"
                    data-v-step="contact-detail-image"
                  ></contact-image>
                </div>
              </div>
              <div v-if="contact.AudioUrl" class="row mt-2">
                <div class="col">
                  <audio controls :src="'/' + $appId + '/' + contact.AudioUrl">
                    <a :href="'/' + $appId + '/' + contact.AudioUrl">
                      Download audio
                    </a>
                  </audio>
                </div>
              </div>
            </div>
          </div>
          <div class="row avoid-break">
            <div class="col">
              <contact-descriptive-list-contact
                :contact-id="contactId"
                :contact="contact"
                :return-to="returnTo + encodeURIComponent('#contact')"
                :date-options="contactDateOptions"
                :allow-edit="!printMode"
              ></contact-descriptive-list-contact>
            </div>
          </div>
          <div class="row avoid-break">
            <div class="col">
              <contact-descriptive-list-marketing
                :contact-id="contactId"
                :contact="contact"
                :return-to="returnTo + encodeURIComponent('#marketing')"
                :date-options="contactDateOptions"
                :search-id="searchId"
                :page="page"
                :allow-edit="!printMode"
              ></contact-descriptive-list-marketing>
            </div>
          </div>
          <div class="row avoid-break">
            <div class="col">
              <contact-descriptive-list-notes
                :contact-id="contactId"
                :contact="contact"
                :return-to="returnTo + encodeURIComponent('#notes')"
                :date-options="contactDateOptions"
                :allow-edit="!printMode"
              ></contact-descriptive-list-notes>
            </div>
          </div>
          <div v-if="$permitted('royalties/access')" class="row avoid-break">
            <div class="col">
              <contact-descriptive-list-royalties
                :contact-id="contactId"
                :contact="contact"
                :return-to="returnTo + encodeURIComponent('#royalties')"
                :date-options="contactDateOptions"
                :allow-edit="!printMode"
                :royalties-data="royaltiesData"
              ></contact-descriptive-list-royalties>
            </div>
          </div>
          <div v-if="!printMode" class="row avoid-break">
            <div class="col">
              <b-card id="contactCardEdit">
                <template #header>
                  <h5 class="d-inline-block">Edit &amp; Status</h5>
                </template>
                <dl v-if="contact" class="row">
                  <dt class="col-sm-3 font-weight-bold text-secondary">
                    Created
                  </dt>
                  <dd class="col-sm-9">
                    {{
                      contact.ContactCreatedOn
                        | parseDate(
                          $userPreferences.dateFormat,
                          contactDateOptions
                        )
                    }}
                    by {{ contact.ContactCreatedByUser }}
                  </dd>
                  <dt class="col-sm-3 font-weight-bold text-secondary">
                    Updated
                  </dt>
                  <dd class="col-sm-9">
                    {{
                      contact.ContactUpdatedOn
                        | parseDate(
                          $userPreferences.dateFormat,
                          contactDateOptions
                        )
                    }}
                    by {{ contact.ContactUpdatedByUser }}
                  </dd>
                </dl>
                <hr />
                <contact-edit-buttons
                  v-if="$permitted('contacts/edit')"
                  :contact-id="contactId"
                  :return-to="returnTo"
                  @deleteClicked="deleteClicked"
                ></contact-edit-buttons>
              </b-card>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <booksonix-tour
      :name="tour.name"
      :steps="tourSteps"
      :options="{ highlight: true }"
    ></booksonix-tour>
    <share-media-modal
      :id="shareMediaModalId"
      :ref="shareMediaModalId"
    ></share-media-modal>
  </div>
</template>

<script>
/**
 * Contains the presentation and application logic for
 * displaying the contact detail page.
 */

import NavigationBar from "../../contacts/NavigationBar.vue";
import MenuBar from "../../contacts/MenuBar.vue";
import InfoIcon from "../../icons/InfoIcon.vue";
import AssignedTags from "../../tags/AssignedTags.vue";
import ContactDescriptiveListGeneral from "../../contacts/ContactDescriptiveListGeneral.vue";
import ContactDescriptiveListContact from "../../contacts/ContactDescriptiveListContact.vue";
import ContactDescriptiveListMarketing from "../../contacts/ContactDescriptiveListMarketing.vue";
import ContactDescriptiveListNotes from "../../contacts/ContactDescriptiveListNotes.vue";
import ContactDescriptiveListRoyalties from "../../contacts/ContactDescriptiveListRoyalties.vue";
import ContactEditButtons from "../../contacts/ContactEditButtons.vue";
import ContactImage from "../../contacts/ContactImage.vue";
import ShareMediaModal from "../../biblio/ShareMediaModal.vue";
import ParseDate from "../../../mixins/parseDate.js";
import { MarkedRecords } from "../../../MarkedRecords.js";
import { HTTP } from "../../../http-common.js";

export default {
  name: "ContactDetailPage",

  components: {
    "contacts-navigationbar": NavigationBar,
    "contacts-menubar": MenuBar,
    "info-icon": InfoIcon,
    "assigned-tags": AssignedTags,
    "contact-descriptive-list-general": ContactDescriptiveListGeneral,
    "contact-descriptive-list-contact": ContactDescriptiveListContact,
    "contact-descriptive-list-marketing": ContactDescriptiveListMarketing,
    "contact-descriptive-list-notes": ContactDescriptiveListNotes,
    "contact-descriptive-list-royalties": ContactDescriptiveListRoyalties,
    "contact-edit-buttons": ContactEditButtons,
    "contact-image": ContactImage,
    "share-media-modal": ShareMediaModal,
  },

  mixins: [ParseDate],

  props: {
    /**
     * ID of the contact
     */
    contactId: {
      type: Number,
      default: null,
    },

    /**
     * Contact object
     */
    contact: {
      type: Object,
      default: () => {},
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      default: null,
    },

    /**
     * Name of the search form where the search
     * was performed
     */
    searchForm: {
      type: String,
      default: null,
    },

    /**
     *  Search type ('form', 'form-tag', 'quick', 'link')
     */
    searchFormType: {
      type: String,
      default: "form",
    },

    /**
     * The current user logon ID
     */
    logonId: {
      type: String,
      required: true,
    },

    /**
     * Page number
     */
    page: {
      type: Number,
      default: null,
    },

    /**
     * Number of pages
     */
    pages: {
      type: Number,
      default: null,
    },

    /**
     * Detailed display format
     */
    format: {
      type: String,
      default: "full",
    },

    /**
     * List of tags that can be assigned to a contact
     */
    tags: {
      type: Array,
      default: null,
    },

    /**
     * Whether the contact has been deleted
     */
    deleted: {
      type: Boolean,
      default: false,
    },

    /**
     * Contact royalty payee data
     * as described in ContactDescriptiveListRoyalties component
     */
    royaltiesData: {
      type: Array,
      default: null,
    },
  },

  data: function () {
    return {
      returnTo:
        "/contacts/detailed/" +
        this.contactId +
        "/" +
        this.searchId +
        "/" +
        this.page,
      markedRecords: this.searchId
        ? new MarkedRecords(this.$baseUrl, this.searchId)
        : null,
      contactDateOptions: {
        day: "dd",
        month: "MMMM",
        year: "yyyy",
        separator: " ",
        format: this.$userPreferences.dateFormat,
      },
      shareMediaModalId: "contactsShareMedia",
      contactDeleted: this.deleted,
      printMode: this.format == "print",
      tour: {
        name: "contact-detailed",
      },
    };
  },

  computed: {
    hasSearchForm: function () {
      return this.searchFormType !== "quick";
    },
    recordMarked: {
      get: function () {
        // read whether the current contactId is in marked records
        if (!this.markedRecords) {
          return false;
        }
        const markedRecords = this.markedRecords.getAll();
        return markedRecords.includes(this.contactId);
      },
      set: function (val) {
        // mark / unmark the current record
        if (this.markedRecords) {
          this.markedRecords.setMarked(this.contactId, val);
        }
      },
    },
    isCorporation: function () {
      return this.contact.CorporateBody != null;
    },
    tourSteps: function () {
      let steps = [
        {
          target: '[data-v-step="contact-detail-navigating"]',
          content:
            "You can jump to a different record within the results list by using this box. Note that this number refers to the position in the search results, not the record number. Use the Next and Previous buttons for navigation between records in the search results.",
          offset: -400,
        },
        {
          target: '[data-v-step="contact-detail-menubar"]',
          content:
            "The links above this section retain all of the functionality from the previous contact display. Use the options to tag or export the contact.",
          offset: -400,
          params: {
            highlight: true,
          },
        },
        {
          target: '[data-v-step="contact-detail-cards"]',
          content:
            "Fields in the contact display are grouped into logical sections to make it easier to find your way around the page. Use the <b>Jump to</b> drop down menu to move quickly between the different sections.",
          offset: -400,
        },
        {
          target: '[data-v-step="contact-detail-image"]',
          content:
            "Hover over the contact image to see basic information about the file. Use the icons in the bottom right corner to open the image in a new tab, download the image or send it by email.",
          offset: -400,
        },
      ];
      if (this.$permitted("contacts/edit")) {
        steps.push({
          target: '[data-v-step="contact-detail-edit"]',
          content:
            "Click on the edit button <i class='fa-solid fa-edit'></i> to open the correct form for editing that section’s data.",
          offset: -400,
        });
      }
      return steps;
    },
  },

  mounted: function () {
    // make the share media modal accessible globally
    this.$root.$refs[this.shareMediaModalId] =
      this.$refs[this.shareMediaModalId];
  },

  methods: {
    /**
     * A mark menu item has been clicked
     */
    markClicked(event, action) {
      switch (action) {
        case "all":
          this.markedRecords.markAll();
          break;
        case "none":
          this.markedRecords.clearAll();
          break;
        case "reverse":
          this.markedRecords.invertAll();
          break;
      }
    },

    deleteClicked: function () {
      this.$bvModal
        .msgBoxConfirm("Are you sure you wish to delete this contact?", {
          title: "Please Confirm",
          okVariant: "success",
          okTitle: "YES",
          cancelVariant: "danger",
          cancelTitle: "NO",
          centered: true,
        })
        .then(async (confirmed) => {
          if (confirmed == true) {
            try {
              const response = await HTTP.delete(
                this.$baseUrl + "contacts/delete/" + this.contactId
              );
              if (response.data == "OK") {
                this.$bvModal.msgBoxOk("Contact deleted", {
                  title: "Confirmation",
                  okVariant: "success",
                });
                this.contactDeleted = true;
              }
            } catch (error) {
              let message = "";
              if (error.response.status == 400) {
                message = error.response.data;
              } else {
                message =
                  "There was an error while trying to delete this contact.";
              }
              this.$bvModal.msgBoxOk(message, {
                title: "Could not delete contact",
                okVariant: "danger",
              });
            }
          }
        });
    },
  },
};
</script>

<style></style>
