<template>
  <b-card id="productCardProductFormats">
    <template #header>
      <a id="formats" />
      <h5 class="d-inline-block">Format</h5>
      <edit-icon
        v-if="$permitted('biblio/edit') && allowEdit"
        class="ml-2 d-print-none"
        :link="
          $baseUrl +
          '/biblio-edit/edit-format/' +
          productId +
          (returnTo ? '?return=' + returnTo : '')
        "
      ></edit-icon>
      <product-quick-jump-dropdown
        ref="formatQuickJump"
        class="float-right"
        :product-id="productId"
        :display="display"
        :has-custom-fields="hasCustomFields"
      ></product-quick-jump-dropdown>
    </template>
    <info-box class="mx-5"
      >Alternate formats are found in the
      <b-link
        @click="
          $refs.formatQuickJump.scrollToElement('productCardRelatedProducts')
        "
        >Related Products</b-link
      >
      section</info-box
    >
    <dl class="row">
      <template v-if="multipleParts || product.getFormatName || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">Product Format</dt>
        <dd v-if="multipleParts || product.getFormatName" class="col-sm-9">
          <template v-if="multipleParts">{{ formatCategoryText }}</template>
          <template v-else
            ><template
              v-if="product.getNumberOfPieces || product.getNumberOfCopies"
              >{{
                product.getNumberOfPieces
                  ? product.getNumberOfPieces
                  : product.getNumberOfCopies
              }}
              x </template
            >{{ product.getFormatName }}</template
          ><template
            v-if="
              product.getFormatDetailsNames &&
              product.getFormatDetailsNames.length > 0
            "
          >
            –
            <span
              v-for="(detail, index) in product.getFormatDetailsNames"
              :key="index"
              >{{ detail
              }}<template
                v-if="
                  index + 1 < product.getFormatDetailsNames.length ||
                  product.getProductPackagingName
                "
                >,
              </template></span
            >
          </template>
          <template v-if="product.getProductPackagingName">
            {{ product.getProductPackagingName }}
          </template>
        </dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
      <template v-if="product.getFormatDescription || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary"></dt>
        <dd class="col-sm-9">{{ product.getFormatDescription }}</dd>
      </template>
      <template v-if="product.getCountryOfManufactureName">
        <dt class="col-sm-3 font-weight-bold text-secondary"></dt>
        <dd class="col-sm-9">
          Manufactured in {{ product.getCountryOfManufactureName }}
        </dd>
      </template>
      <template
        v-if="
          product.getProductPrimaryContentType ||
          product.getProductContentTypes ||
          showAllFields
        "
      >
        <dt class="col-sm-3 font-weight-bold text-secondary"></dt>
        <dd class="col-sm-9">
          Content Types :
          <template v-if="product.getProductPrimaryContentTypeName">
            {{
              product.getProductPrimaryContentTypeName +
              (product.getProductContentTypeNames ? "; " : "")
            }}
          </template>
          <template
            v-for="(
              contentTypeName, ctIndex
            ) in product.getProductContentTypeNames"
          >
            {{ (ctIndex != 0 ? " ; " : "") + contentTypeName }}
          </template>
        </dd>
      </template>
      <template v-if="product.getPaperCertificateTypeName || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary"></dt>
        <dd v-if="product.getPaperCertificateTypeName" class="col-sm-9">
          {{ product.getPaperCertificateTypeName
          }}<template v-if="product.getPaperCertificateNumber"
            >, Cert no. {{ product.getPaperCertificateNumber }}</template
          ><template v-if="product.getPaperPrePostConsumerWastePercent"
            >, {{ product.getPaperPrePostConsumerWastePercent }}% Claimed Pre-
            and Post-Consumer Waste</template
          >
        </dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
      <template v-if="product.getGreenInks">
        <dt class="col-sm-3 font-weight-bold text-secondary"></dt>
        <dd class="col-sm-9">
          ‘Green’ Inks : Yes
          <template v-if="product.getGreenInkDetails != ''">
            – {{ product.getGreenInkDetails }}
          </template>
        </dd>
      </template>
      <template v-else-if="showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary"></dt>
        <dd class="col-sm-9">‘Green’ Inks : No</dd>
      </template>
      <template v-if="product.getGreenAdhesives">
        <dt class="col-sm-3 font-weight-bold text-secondary"></dt>
        <dd class="col-sm-9">
          ‘Green’ Adhesives : Yes
          <template v-if="product.getGreenAdhesiveDetails != ''">
            – {{ product.getGreenAdhesiveDetails }}
          </template>
        </dd>
      </template>
      <template v-else-if="showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary"></dt>
        <dd class="col-sm-9">‘Green’ Adhesives : No</dd>
      </template>
      <template v-if="product.getGreenProducedPaper">
        <dt class="col-sm-3 font-weight-bold text-secondary"></dt>
        <dd class="col-sm-9">
          Paper produced using ‘Green’ technologies : Yes
          <template v-if="product.getGreenProducedPaperDetails != ''">
            – {{ product.getGreenProducedPaperDetails }}
          </template>
        </dd>
      </template>
      <template v-else-if="showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary"></dt>
        <dd class="col-sm-9">Paper produced using ‘Green’ technologies : No</dd>
      </template>
      <template v-if="product.getCarbonGHGEmissionsScheme">
        <dt class="col-sm-3 font-weight-bold text-secondary"></dt>
        <dd class="col-sm-9">
          Carbon/GHG Emissions: Scheme:
          {{ product.getCarbonGHGEmissionsScheme.getSchemeTypeName }}
          <template
            v-if="product.getCarbonGHGEmissionsScheme.getSchemeDescriptor"
          >
            ; Scheme Descriptor:
            {{ product.getCarbonGHGEmissionsScheme.getSchemeDescriptor }}
          </template>
          <template
            v-if="product.getCarbonGHGEmissionsScheme.getCertificationNumber"
          >
            ; Cert No.:
            {{ product.getCarbonGHGEmissionsScheme.getCertificationNumber }}
          </template>
          <template
            v-if="product.getCarbonGHGEmissionsScheme.getCertificationUrl"
          >
            ; Cert URL:
            {{ product.getCarbonGHGEmissionsScheme.getCertificationUrl }}
          </template>
          <template v-if="product.getCarbonGHGEmissionsScheme.getCO2Emissions">
            ; CO₂e emissions (scope
            {{ product.getCarbonGHGEmissionsScheme.getCO2Scope }}):
            {{ product.getCarbonGHGEmissionsScheme.getCO2Emissions }}
            kg
          </template>
        </dd>
      </template>
      <template v-else-if="showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary"></dt>
        <dd class="col-sm-9">Carbon/GHG Emissions:</dd>
      </template>
      <template v-if="product.getEUDRRawMaterialLocations">
        <dt class="col-sm-3 font-weight-bold text-secondary"></dt>
        <dd class="col-sm-9">EUDR Raw Material Locations:</dd>
        <template
          v-for="(
            eudrRawMaterialLocation, rmlIndex
          ) in product.getEUDRRawMaterialLocations"
        >
          <dt :key="'rml0-t' + rmlIndex" class="col-sm-4"></dt>
          <dd :key="'rml0-d' + rmlIndex" class="col-sm-8 raw-material-location">
            {{ eudrRawMaterialLocation.getTypeName }} :
            {{ eudrRawMaterialLocation.getCountryName }}
            <template v-if="eudrRawMaterialLocation.getSpeciesName">
              ; species <i>{{ eudrRawMaterialLocation.getSpeciesName }}</i>
            </template>
            <template v-if="eudrRawMaterialLocation.getHarvestDate">
              ; harvested
              {{
                eudrRawMaterialLocation.getHarvestDate
                  | parseDate(dateOptions.format, dateOptions)
              }}
            </template>
            <template
              v-for="eudrRawMaterialLocationCoords in eudrRawMaterialLocation.getPlotsCoords"
            >
              ; plot location :
              {{ eudrRawMaterialLocationCoords }}
            </template>
          </dd>
        </template>
      </template>
      <template v-else-if="showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary"></dt>
        <dd class="col-sm-9">EUDR Raw Material Locations:</dd>
      </template>
      <template
        v-if="product.getEUDRDueDiligenceStatementReferences || showAllFields"
      >
        <dt class="col-sm-3 font-weight-bold text-secondary"></dt>
        <dd class="col-sm-9">
          EUDR Due Diligence Statement references:
          <template v-if="product.getEUDRDueDiligenceStatementReferences">
            {{ product.getEUDRDueDiligenceStatementReferences.join(", ") }}
          </template>
        </dd>
      </template>
      <template
        v-if="
          product.getEUDRAssociatedDueDiligenceStatementReferences ||
          showAllFields
        "
      >
        <dt class="col-sm-3 font-weight-bold text-secondary"></dt>
        <dd class="col-sm-9">
          EUDR Associated Due Diligence Statement references:
          <template
            v-if="product.getEUDRAssociatedDueDiligenceStatementReferences"
          >
            {{
              product.getEUDRAssociatedDueDiligenceStatementReferences.join(
                ", "
              )
            }}
          </template>
        </dd>
      </template>
      <template v-if="product.getEUDRNationalLawCompliance || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary"></dt>
        <dd class="col-sm-9">
          EUDR National Law Compliance:
          <template v-if="product.getEUDRNationalLawCompliance">
            Yes – {{ product.getEUDRNationalLawComplianceCountryNames }}
          </template>
          <template v-else>No</template>
        </dd>
      </template>
      <template v-if="product.getEUDRDeforestationFree || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary"></dt>
        <dd class="col-sm-9">
          EUDR Deforestation-Free:
          <template v-if="product.getEUDRDeforestationFree">Yes</template>
          <template v-else>No</template>
        </dd>
      </template>
      <template v-for="(part, index) in product.getProductParts">
        <dt
          :key="'prod-part-dt-' + index"
          class="col-sm-3 font-weight-bold text-secondary pl-5"
        >
          Contains
        </dt>
        <dd :key="'prod-part-dd-' + index" class="col-sm-9">
          <div>
            <template
              v-if="part.getNumberOfPieces > 0 || part.getNumberOfCopies > 0"
            >
              {{
                part.getNumberOfPieces
                  ? part.getNumberOfPieces
                  : part.getNumberOfCopies
              }}
              x </template
            >{{ part.getFormatName
            }}<template v-if="part.getFormatDetailsNames.length > 0">
              -
            </template>
            <span
              v-for="(partDetail, partIndex) in part.getFormatDetailsNames"
              :key="partIndex"
              >{{ partDetail
              }}<template
                v-if="
                  index + 1 < part.getFormatDetailsNames.length ||
                  part.getProductPackagingName
                "
                >,
              </template></span
            >
            <template v-if="part.getProductPackagingName">
              {{ part.getProductPackagingName }}
            </template>
          </div>
          <div v-if="part.getFormatDescription">
            {{ part.getFormatDescription }}
          </div>
          <div v-if="part.getCountryOfManufactureName">
            Manufactured in {{ part.getCountryOfManufactureName }}
          </div>
          <div v-if="part.getProductContentTypes || showAllFields">
            Content Types :
            <template
              v-for="(
                contentTypeName, ctIndex
              ) in part.getProductContentTypeNames"
            >
              {{ (ctIndex != 0 ? " ; " : "") + contentTypeName }}
            </template>
          </div>
          <div v-if="part.getPaperCertificateTypeName">
            {{ part.getPaperCertificateTypeName
            }}<template v-if="part.getPaperCertificateNumber"
              >, Cert no. {{ part.getPaperCertificateNumber }}</template
            ><template v-if="part.getPaperPrePostConsumerWastePercent"
              >, {{ part.getPaperPrePostConsumerWastePercent }}% Claimed Pre-
              and Post-Consumer Waste</template
            >
          </div>
          <div v-if="part.getGreenInks">
            ‘Green’ Inks : Yes
            <template v-if="part.getGreenInkDetails != ''">
              – {{ part.getGreenInkDetails }}
            </template>
          </div>
          <div v-else-if="showAllFields">‘Green’ Inks : No</div>
          <div v-if="part.getGreenAdhesives">
            ‘Green’ Adhesives : Yes
            <template v-if="part.getGreenAdhesiveDetails != ''">
              – {{ part.getGreenAdhesiveDetails }}
            </template>
          </div>
          <div v-else-if="showAllFields">‘Green’ Adhesives : No</div>
          <div v-if="part.getGreenProducedPaper">
            Paper produced using ‘Green’ technologies : Yes
            <template v-if="part.getGreenProducedPaperDetails != ''">
              – {{ part.getGreenProducedPaperDetails }}
            </template>
          </div>
          <div v-else-if="showAllFields">
            Paper produced using ‘Green’ technologies : No
          </div>
          <div v-if="part.getCarbonGHGEmissionsScheme">
            Carbon/GHG Emissions: Scheme:
            {{ part.getCarbonGHGEmissionsScheme.getSchemeTypeName }}
            <template
              v-if="part.getCarbonGHGEmissionsScheme.getSchemeDescriptor"
            >
              ; Scheme Descriptor:
              {{ part.getCarbonGHGEmissionsScheme.getSchemeDescriptor }}
            </template>
            <template
              v-if="part.getCarbonGHGEmissionsScheme.getCertificationNumber"
            >
              ; Cert No.:
              {{ part.getCarbonGHGEmissionsScheme.getCertificationNumber }}
            </template>
            <template
              v-if="part.getCarbonGHGEmissionsScheme.getCertificationUrl"
            >
              ; Cert URL:
              {{ part.getCarbonGHGEmissionsScheme.getCertificationUrl }}
            </template>
            <template v-if="part.getCarbonGHGEmissionsScheme.getCO2Emissions">
              ; CO₂e emissions (scope
              {{ part.getCarbonGHGEmissionsScheme.getCO2Scope }}):
              {{ part.getCarbonGHGEmissionsScheme.getCO2Emissions }}
              kg
            </template>
          </div>
          <div v-else-if="showAllFields">Carbon/GHG Emissions:</div>
          <template v-if="part.getEUDRRawMaterialLocations">
            <div>EUDR Raw Material Locations:</div>
            <div
              v-for="(
                eudrRawMaterialLocation, rmlIndex
              ) in part.getEUDRRawMaterialLocations"
              :key="'rml' + rmlIndex"
              class="raw-material-location"
            >
              {{ eudrRawMaterialLocation.getTypeName }} :
              {{ eudrRawMaterialLocation.getCountryName }}
              <template v-if="eudrRawMaterialLocation.getSpeciesName">
                ; species <i>{{ eudrRawMaterialLocation.getSpeciesName }}</i>
              </template>
              <template v-if="eudrRawMaterialLocation.getHarvestDate">
                ; harvested
                {{
                  eudrRawMaterialLocation.getHarvestDate
                    | parseDate(dateOptions.format, dateOptions)
                }}
              </template>
              <template
                v-for="eudrRawMaterialLocationCoords in eudrRawMaterialLocation.getPlotsCoords"
              >
                ; plot location :
                {{ eudrRawMaterialLocationCoords }}
              </template>
            </div>
          </template>
          <div v-else-if="showAllFields">EUDR Raw Material Locations:</div>
          <div
            v-if="part.getEUDRDueDiligenceStatementReferences || showAllFields"
          >
            EUDR Due Diligence Statement references:
            <template v-if="part.getEUDRDueDiligenceStatementReferences">
              {{ part.getEUDRDueDiligenceStatementReferences.join(", ") }}
            </template>
          </div>
          <div
            v-if="
              part.getEUDRAssociatedDueDiligenceStatementReferences ||
              showAllFields
            "
          >
            EUDR Associated Due Diligence Statement references:
            <template
              v-if="part.getEUDRAssociatedDueDiligenceStatementReferences"
            >
              {{
                part.getEUDRAssociatedDueDiligenceStatementReferences.join(", ")
              }}
            </template>
          </div>
          <div v-if="part.getEUDRNationalLawCompliance || showAllFields">
            EUDR National Law Compliance:
            <template v-if="part.getEUDRNationalLawCompliance">
              Yes – {{ part.getEUDRNationalLawComplianceCountryNames }}
            </template>
            <template v-else>No</template>
          </div>
          <div v-if="part.getEUDRDeforestationFree || showAllFields">
            EUDR Deforestation-Free:
            <template v-if="product.getEUDRDeforestationFree">Yes</template>
            <template v-else>No</template>
          </div>
          <div
            v-for="(identifier, idIndex) in part.getAllProductIdentifiers"
            :key="'partIdentifier-' + idIndex"
          >
            {{ identifier.typeName }}
            <a
              :href="
                $baseUrl +
                'search/link/biblio/' +
                searchId +
                '/' +
                page +
                '/biblio?ALLID=' +
                identifier.value
              "
              target="_blank"
              >{{ identifier.value }}</a
            >
            <barcode-link
              v-if="identifier.value"
              :id="'barcode-part-' + index + '-' + idIndex"
              class="pl-2"
              type="ISBN"
              :value="identifier.value"
              :logon-id="logonId"
            ></barcode-link>
          </div>
        </dd>
      </template>
      <template v-if="product.getTradeCategoryName || showAllFields">
        <dt class="col-sm-3 font-weight-bold text-secondary">Trade Category</dt>
        <dd v-if="product.getTradeCategoryName" class="col-sm-9">
          {{ product.getTradeCategoryName }}
        </dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
      <template
        v-if="$featureEnabled('productUKTaxBand') && product.getUKTaxBand"
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">UK Tax Band</dt>
        <dd class="col-9">
          {{ product.getUKTaxBand }}
        </dd>
      </template>
      <template
        v-if="
          (product.getClassifications.getAll &&
            Object.keys(product.getClassifications.getAll).length > 0) ||
          showAllFields
        "
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Product Classification
        </dt>
        <dd
          v-if="
            product.getClassifications.getAll &&
            Object.keys(product.getClassifications.getAll).length > 0
          "
          class="col-sm-9"
        >
          <div
            v-for="(classification, index) of product.getClassifications.getAll"
            :key="index"
          >
            {{ classification.getTypeName }} : {{ classification.getValue }}
            <span v-if="classification.getPercentage"
              >({{ classification.getPercentage }}%)</span
            >
          </div>
        </dd>
        <dd v-else class="col-sm-9">—</dd>
      </template>
      <template
        v-if="
          (product.getMeasurements.getAll &&
            Object.keys(product.getMeasurements.getAll).length > 0) ||
          showAllFields
        "
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Dimensions and Measurements
        </dt>
        <dd class="col-sm-9">
          <template
            v-if="
              !(
                product.getMeasurements.getAll &&
                Object.keys(product.getMeasurements.getAll).length > 0
              )
            "
            >—</template
          >
        </dd>
      </template>
      <template
        v-if="
          product.getMeasurements.getAll &&
          Object.keys(product.getMeasurements.getAll).length > 0
        "
      >
        <template
          v-for="(measurement, index) in product.getMeasurements.getAll"
        >
          <dt
            :key="'measurement-dt-' + index"
            class="col-sm-3 pl-5 font-weight-bold text-secondary"
          >
            {{ getTypeNameString(measurement) }}
          </dt>
          <dd :key="'measurement-dd-' + index" class="col-sm-9">
            {{ measurement.getValue }}
            <template v-if="measurement.getUnit == 'gr'">g</template
            ><template v-else>{{ measurement.getUnit }}</template>
          </dd>
        </template>
      </template>
      <template
        v-if="
          (product.getMapScales && product.getMapScales.length > 0) ||
          showAllFields
        "
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">Map Scales</dt>
        <dd class="col-sm-9">
          <template
            v-if="product.getMapScales && product.getMapScales.length > 0"
          >
            <span
              v-for="(scale, index) in product.getMapScales"
              :key="'mapscale-' + index"
            >
              1:{{ scale
              }}<template v-if="index < product.getMapScales.length - 1"
                >,
              </template>
            </span>
          </template>
          <template
            v-if="!(product.getMapScales && product.getMapScales.length > 0)"
            >—</template
          >
        </dd>
      </template>
      <template v-if="product.getExtents.getAll">
        <template v-for="(extent, index) in product.getExtents.getAll">
          <dt
            :key="'extent-dt-' + index"
            class="col-sm-3 font-weight-bold text-secondary"
          >
            {{ getExtentTypeNameString(extent) }}
          </dt>
          <dd :key="'extent-dd-' + index" class="col-sm-9">
            <template v-if="extent.getType == '09'">
              <template v-if="extent.getUnit == '11'">{{
                extent.getValue
              }}</template>
              <template v-else>
                {{ extent.getValue | formatHHmm }}
                <span class="text-muted">{{
                  extent.getValue | formatHHmmUnits
                }}</span>
              </template>
            </template>
            <template v-else>
              {{ extent.getValue }}
              <template v-if="extent.getType == '22'">{{
                extent.getUnitName
              }}</template>
            </template>
          </dd>
        </template>
      </template>
      <template
        v-if="
          (product.getDigitalConstraints &&
            Object.keys(product.getDigitalConstraints.getAll).length > 0) ||
          product.getDigitalLicenseType ||
          product.getDigitalTechnicalProtection
        "
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Digital Publication Information
        </dt>
        <dd class="col-9"></dd>
        <template v-if="product.getDigitalTechnicalProtection">
          <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">
            Technical Protection
          </dt>
          <dd class="col-9">{{ product.getDigitalTechnicalProtectionName }}</dd>
        </template>
        <template
          v-if="
            product.getDigitalConstraints &&
            product.getDigitalConstraints.getAll &&
            Object.keys(product.getDigitalConstraints.getAll).length > 0
          "
        >
          <template
            v-for="(constraint, index) in product.getDigitalConstraints.getAll"
          >
            <dt
              :key="'dt-constraint-' + index"
              class="col-sm-3 pl-5 font-weight-bold text-secondary"
            >
              {{ constraint.getTypeName }}
            </dt>
            <dd :key="'dd-constraint-' + index" class="col-9">
              {{ constraint.getStatusName
              }}<template
                v-if="
                  typeof constraint.getAllLimits == 'object' &&
                  Object.keys(constraint.getAllLimits).length > 0
                "
              >
                <span v-if="constraint.getLimitPageRange">
                  , Pages {{ constraint.getLimitPageRange[0] }} to
                  {{ constraint.getLimitPageRange[1] }}
                </span>
                <span
                  v-for="(limit, limitIndex) in constraint.getAllLimits"
                  :key="limitIndex"
                >
                  <template v-if="limitIndex != 11 && limitIndex != 12">
                    , Maximum {{ limit.amount }} {{ limit.type }}
                  </template>
                </span>
              </template>
            </dd>
          </template>
        </template>
        <template v-if="product.getDigitalLicenseType">
          <dt class="col-sm-3 font-weight-bold text-secondary">
            Digital Product License
          </dt>
          <dd class="col-9"></dd>
          <template v-if="product.getDigitalLicenseName">
            <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">Name</dt>
            <dd class="col-9">{{ product.getDigitalLicenseName }}</dd>
          </template>
          <template v-if="product.getDigitalLicenseTypeName">
            <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">Type</dt>
            <dd class="col-9">{{ product.getDigitalLicenseTypeName }}</dd>
          </template>
          <template v-if="product.getDigitalLicenseLink">
            <dt class="col-sm-3 pl-5 font-weight-bold text-secondary">Link</dt>
            <dd class="col-9">{{ product.getDigitalLicenseLink }}</dd>
          </template>
        </template>
        <template
          v-if="
            product.getAllEbookAccessibility &&
            typeof product.getAllEbookAccessibility == 'object' &&
            Object.keys(product.getAllEbookAccessibility).length > 0
          "
        >
          <dt class="col-sm-3 font-weight-bold text-secondary">
            eBook Acessibility
          </dt>
          <dd class="col-9">
            <div
              v-for="(accessibility, index) in product.getAllEbookAccessibility"
              :key="index"
            >
              <template v-if="accessibility.value">
                {{ accessibility.type }} : {{ accessibility.value }}
              </template>
              <template v-else-if="accessibility.dateValue">
                {{ accessibility.type }} :
                {{
                  accessibility.dateValue
                    | parseDate(dateOptions.format, dateOptions)
                }}
              </template>
              <template v-else>
                {{ accessibility.type }}
              </template>
            </div>
          </dd>
        </template>
      </template>
      <template
        v-if="
          (product.getAllBatteryTypes &&
            Object.keys(product.getAllBatteryTypes).length > 0) ||
          product.getBatteryCapacity ||
          showAllFields
        "
      >
        <dt class="col-sm-3 font-weight-bold text-secondary">
          Battery Information
        </dt>
        <dd class="col-sm-9">
          <template
            v-if="
              product.getAllBatteryTypes &&
              Object.keys(product.getAllBatteryTypes).length > 0
            "
          >
            <div
              v-for="(battery, index) in product.getAllBatteryTypes"
              :key="index"
            >
              {{ battery.type }}
              <span v-if="battery.value">: {{ battery.value }}</span>
            </div>
          </template>
          <template v-if="product.getBatteryCapacity"
            >Battery capacity (Watt-hours) :
            {{ product.getBatteryCapacity }}</template
          >
          <template
            v-if="
              !(
                (product.getAllBatteryTypes &&
                  Object.keys(product.getAllBatteryTypes).length > 0) ||
                product.getBatteryCapacity
              )
            "
            >—</template
          >
        </dd>
      </template>
      <template v-if="$featureEnabled('lightningSourceBooktype')">
        <template v-if="product.getLightningSourceBooktypeName">
          <dt class="col-sm-3 font-weight-bold text-secondary">
            Lightning Source Booktype
          </dt>
          <dd class="col-9">{{ product.getLightningSourceBooktypeName }}</dd>
        </template>
        <template v-if="product.getLightningSourceOrderTypeName">
          <dt class="col-sm-3 font-weight-bold text-secondary">
            Lightning Source Order Type
          </dt>
          <dd class="col-9">{{ product.getLightningSourceOrderTypeName }}</dd>
        </template>
      </template>
    </dl>
  </b-card>
</template>

<script>
/**
 * Displays a card containing a desciptive list
 * of product formats, parts, dimensions and classifications
 */
import BarcodeLink from "./BarcodeLink.vue";
import DisplaySetting from "../../mixins/productDisplaySetting.js";
import ParseDate from "../../mixins/parseDate.js";
import ProductQuickJumpDropdown from "./ProductQuickJumpDropdown.vue";
import InfoBox from "../ui/InfoBox.vue";

export default {
  name: "ProductFormats",

  components: {
    "barcode-link": BarcodeLink,
    "product-quick-jump-dropdown": ProductQuickJumpDropdown,
    "info-box": InfoBox,
  },

  filters: {
    formatHHmm(value) {
      let formatted = "";
      if (parseInt(value.slice(0, 3))) {
        formatted = parseInt(value.slice(0, 3), 10).toString();
      }
      if (parseInt(value.slice(3, 5))) {
        if (formatted) {
          formatted += ":";
          formatted += value.slice(3, 5);
        } else {
          formatted += parseInt(value.slice(3, 5), 10).toString();
        }
      }
      if (formatted) {
        formatted += ":";
      }
      formatted += value.slice(5);
      return formatted;
    },
    formatHHmmUnits(value) {
      let units = "";
      if (parseInt(value.slice(0, 3))) {
        units = "hh";
      }
      if (parseInt(value.slice(3, 5))) {
        if (units) {
          units += ":";
        }
        units += "mm";
      }
      if (units) {
        units += ":";
      }
      units += "ss";
      return "(" + units + ")";
    },
  },

  mixins: [DisplaySetting, ParseDate],

  props: {
    /**
     * The id of the product
     */
    productId: {
      type: [Number, String],
      default: null,
    },

    /**
     * The current user logon ID
     */
    logonId: {
      type: String,
      required: true,
    },

    /**
     * Search id
     */
    searchId: {
      type: Number,
      default: null,
    },

    /**
     * Page number
     */
    page: {
      type: Number,
      default: null,
    },

    /**
     * Product data
     *
     * The data passed in must be an assosiative array (object in JS) of product data.
     * The keys of the array must match those defined in BooksonixProduct.php
     * Use the dump method to get an appropriate aray of product data
     *
     * Only format related fields are required for this component
     */
    product: {
      type: Object,
      default: () => {
        return {};
      },
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },

    /**
     * Whether the card should link to an edit page
     */
    allowEdit: {
      type: Boolean,
      default: true,
    },

    /**
     * Whether the product contains custom fields
     */
    hasCustomFields: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    formatCategoryText: function () {
      switch (this.product.getFormatCategory) {
        case "X":
          return "Mixed-media product";
        case "M":
          return "Multiple-item retail product";
        default:
          return "";
      }
    },
    multipleParts: function () {
      if (
        this.product.getFormatCategory == "X" ||
        this.product.getFormatCategory == "M"
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    /**
     * Returns the appropriate type name for a given
     * measurement type code. Some types has customised
     * display names on forms.
     */
    getTypeNameString(measurement) {
      if (measurement.getType == "08") {
        return "Weight";
      }
      if (measurement.getType == "03") {
        return "Depth";
      }
      return measurement.getTypeName;
    },
    /**
     * Returns the appropriate type name for a given
     * extent type code. Some types has customised
     * display names on forms.
     */
    getExtentTypeNameString(extent) {
      if (extent.getType == "02") {
        return "Words";
      }
      if (extent.getType == "05") {
        return "Pages";
      }
      if (extent.getType == "09" && extent.getUnit == "11") {
        return "Track Count";
      }
      return extent.getTypeName;
    },
  },
};
</script>

<style>
dd.raw-material-location {
  text-indent: -3em;
}
div.raw-material-location {
  padding-left: 4em;
  text-indent: -2em;
}
</style>
